import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Img from 'gatsby-image'

interface Props {
  cocktail: {
    id: string
    code: string
    tags: Array<string>
    photo: string
    fields: {
      slug: string
    }
  }
}

function CocktailItem(props: Props): JSX.Element {
  const { cocktail } = props

  const { t } = useTranslation()

  return (
    <Link key={cocktail.id} to={cocktail.fields.slug}>
      <div className="flex">
        <div className="w-12 flex items-center">
          <div className="h-10 w-10">
            <Img
              className="w-10 h-10 object-cover object-center rounded-full"
              fluid={cocktail.imageFluid}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="h-12 border-b ml-4 flex items-center justify-between">
            <span>{t(`cocktails.${cocktail.code}`)}</span>
            <IoIosArrowForward className="text-gray-500" />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default CocktailItem
