import React from 'react'
import CocktailItem from 'components/cocktails/cocktail-item'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface CocktailListProps {
  cocktails: {
    id: string
    code: string
    photo: string
    tags: Array<string>
    fields: {
      slug: string
    }
    imageFluid: {
      base64: string
    }
  }[]
}

const CocktailList: React.FC<CocktailListProps> = props => {
  const { t } = useTranslation()

  function ibaClassicCocktails() {
    return props.cocktails.filter(cocktail =>
      cocktail.tags.includes('iba-classic')
    )
  }

  function ibaUnforgettablesCocktails() {
    return props.cocktails.filter(cocktail =>
      cocktail.tags.includes('iba-unforgettables')
    )
  }

  function ibaNewEraCocktails() {
    return props.cocktails.filter(cocktail =>
      cocktail.tags.includes('iba-new-era')
    )
  }

  return (
    <div className="flex flex-col w-full md:flex-row">
      {ibaClassicCocktails().length > 0 && (
        <div className="md:w-1/3 md:px-1">
          <div className="text-3xl font-light">{t('titles.iba_classic')}</div>
          {ibaClassicCocktails().map(cocktail => (
            <CocktailItem cocktail={cocktail} />
          ))}
        </div>
      )}
      {ibaUnforgettablesCocktails().length > 0 && (
        <div className="md:w-1/3 md:px-1">
          <div className="text-3xl font-light">
            {t('titles.iba_unforgettables')}
          </div>
          {ibaUnforgettablesCocktails().map(cocktail => (
            <CocktailItem cocktail={cocktail} />
          ))}
        </div>
      )}
      {ibaNewEraCocktails().length > 0 && (
        <div className="md:w-1/3 md:px-1">
          <div className="text-3xl font-light">{t('titles.iba_new_era')}</div>
          {ibaNewEraCocktails().map(cocktail => (
            <CocktailItem cocktail={cocktail} />
          ))}
        </div>
      )}
    </div>
  )
}

export default CocktailList
