import React, { useState, useEffect, ChangeEvent } from 'react'
import CocktailList from 'components/cocktails/cocktail-list'
import * as JsSearch from 'js-search'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Ingredient {
  code: string
}
interface CocktailsData {
  allCocktailsJson: {
    edges: {
      node: {
        id: string
        code: string
        photo: string
        fields: {
          slug: string
        }
        ingredients: Array<Ingredient>
      }
    }[]
  }
}

const Index: React.FC = () => {
  const data: CocktailsData = useStaticQuery(graphql`
    query {
      allCocktailsJson(
        sort: { fields: code }
      ) {
        edges {
          node {
            id
            code
            photo
            tags
            ingredients {
              code
            }
            fields {
              slug
            }
          }
        }
      }
      allImageSharp {
        nodes {
          original {
            src
          }
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  const [searchService, setSearchService] = useState(
    new JsSearch.Search(['node', 'id'])
  )
  const [searchResult, setSearchResult] = useState(data.allCocktailsJson.edges.map(({ node }) => {
    return {
      node: {
        imageFluid: data.allImageSharp.nodes.find((imgNode) => imgNode.original.src.includes(node.code)).fluid,
        ...node,
      }
    }
  }))

  useEffect(() => {
    const search = new JsSearch.Search(['node', 'id'])
    search.addIndex(['node', 'translatedName'])
    search.addIndex(['node', 'translatedIngredients'])

    const translatedData = searchResult.map(({ node }) => {
      return {
        node: {
          translatedName: t(`cocktails.${node.code}`),
          translatedIngredients: node.ingredients.map((ingredient) => t(`ingredients.${ingredient.code}`)),
          ...node,
        },
      }
    })

    search.addDocuments(translatedData)
    setSearchService(search)
  }, [data.allCocktailsJson.edges])

  function filterCocktails(e: ChangeEvent<HTMLInputElement>) {
    const result = searchService.search(e.target.value)

    if (e.target.value.length === 0) {
      setSearchResult(data.allCocktailsJson.edges)
      return
    }

    setSearchResult(result)
  }

  return (
    <>
      <Helmet title={t('titles.main')} defer={false} />
      <header className="p-4 font-rubik">
        <div className="text-base">
          <input
            className="border w-full py-1 rounded-full px-3"
            type="text"
            placeholder={t('titles.filter')}
            onChange={filterCocktails}
          />
        </div>
      </header>
      <main className="px-4 font-rubik pb-20">
        <CocktailList cocktails={searchResult.map(elem => elem.node)} />
      </main>
    </>
  )
}

export default Index
